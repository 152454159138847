.sideBox{
    height: 100%;
    background-color: #444;
    color: #fcfcfc;
    font-size: 0.9rem;
    .infoHead{
        font-size: 1.2rem;
        padding: 1rem;
    }
    .iconSize{
        font-size: 1.2rem;
        margin-right: 1rem;
    }
    .infoContext{
        padding-left: 0.5rem;
        font-size: 0.9rem;
        text-align: justify;
        padding-right: 0.5rem;
    }
    .iconContact{
        margin-left: 1.5rem;
        margin-right: 0.2rem;
        padding: 0.5rem;
    }
    .contactContext{
        margin-top: -0.9rem;
        font-size: 0.9rem;
        a{
            color: #fcfcfc;
            text-decoration: none;
        }
    }
    .intIcon{
        font-size: 1.4rem;
    }
    .skillRow{
        border-bottom: 1px solid gainsboro;
        padding: 0.5rem;
    }
}