.workexpBox{
    margin-top: 0.2rem;
    color: #444;
    .headText{
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0.2rem;
        background-color: #666;
        color: #fff;
    }
    .subHead{
        margin-top: 0.2rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: #000;
    }
    .timeText{
        font-size: 0.9rem;
        font-weight: 600;
        margin-left: 0.2rem
    }
    .profileText{
        font-size: 0.9rem;
        font-weight: 700;
    }
    .projectTitle{
        font-weight: 700;
    }
    .kraBox{
        margin-left: 1.5rem;
        margin-top: 0.2rem;
        .conText{
            font-size: 0.6rem;
            text-align: justify;
            color: #444;
        }
        .projectHead{
            font-weight: 600;
        }
    }
}