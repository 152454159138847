.headerBox{
    background-color: #fc0;
    // padding-top: 1rem;
    height: 5.5rem;
    color: #000;
    // box-shadow: 0 2px 18px 0 rgba(182,178,178,.5);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // border-bottom: 1px solid gainsboro;
    .nameText{
        font-size: 2.2rem;
        font-weight: 800;
    }
    .positionText{
        font-size: 1rem;
        font-weight: 500;
    }
}