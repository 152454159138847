@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerBox {
  background-color: #fc0;
  height: 5.5rem;
  color: #000;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }
  .headerBox .nameText {
    font-size: 2.2rem;
    font-weight: 800; }
  .headerBox .positionText {
    font-size: 1rem;
    font-weight: 500; }

.sideBox {
  height: 100%;
  background-color: #444;
  color: #fcfcfc;
  font-size: 0.9rem; }
  .sideBox .infoHead {
    font-size: 1.2rem;
    padding: 1rem; }
  .sideBox .iconSize {
    font-size: 1.2rem;
    margin-right: 1rem; }
  .sideBox .infoContext {
    padding-left: 0.5rem;
    font-size: 0.9rem;
    text-align: justify;
    padding-right: 0.5rem; }
  .sideBox .iconContact {
    margin-left: 1.5rem;
    margin-right: 0.2rem;
    padding: 0.5rem; }
  .sideBox .contactContext {
    margin-top: -0.9rem;
    font-size: 0.9rem; }
    .sideBox .contactContext a {
      color: #fcfcfc;
      text-decoration: none; }
  .sideBox .intIcon {
    font-size: 1.4rem; }
  .sideBox .skillRow {
    border-bottom: 1px solid gainsboro;
    padding: 0.5rem; }

.educationBox {
  color: #666; }
  .educationBox .headText {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.2rem;
    background-color: #666;
    color: #fff; }
  .educationBox .eduBox {
    padding-top: 1rem; }
    .educationBox .eduBox .headContext {
      font-size: 1.2rem;
      font-weight: 700;
      color: #111;
      margin-top: 0.5rem; }
    .educationBox .eduBox .mainContext {
      font-size: 1rem;
      margin-left: 2rem;
      text-align: justify;
      color: #666;
      padding-top: 0.5rem; }
    .educationBox .eduBox .yearText {
      font-size: 0.8rem;
      font-weight: 800;
      margin-left: 1rem; }

.workexpBox {
  margin-top: 0.2rem;
  color: #444; }
  .workexpBox .headText {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.2rem;
    background-color: #666;
    color: #fff; }
  .workexpBox .subHead {
    margin-top: 0.2rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: #000; }
  .workexpBox .timeText {
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 0.2rem; }
  .workexpBox .profileText {
    font-size: 0.9rem;
    font-weight: 700; }
  .workexpBox .projectTitle {
    font-weight: 700; }
  .workexpBox .kraBox {
    margin-left: 1.5rem;
    margin-top: 0.2rem; }
    .workexpBox .kraBox .conText {
      font-size: 0.6rem;
      text-align: justify;
      color: #444; }
    .workexpBox .kraBox .projectHead {
      font-weight: 600; }

body {
  font-family: 'Roboto', sans-serif; }

.appBox {
  background-color: #E9EBEE;
  box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 0.5);
  overflow: hidden; }

.bgContext {
  background-color: #000; }

.m-b-d {
  margin-bottom: 5rem; }

.p-r-n {
  padding-right: 0 !important; }

.p-l-n {
  padding-left: 0 !important; }

.p-r-l-d {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-t-1 {
  margin-top: 1rem; }

.m-t-2 {
  margin-top: 2rem; }

.contextArea {
  background-color: #fff; }

.footerBar {
  height: 2rem;
  background-color: #fc0; }

.personalInfo {
  color: #333; }
  .personalInfo .headText {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.2rem;
    background-color: #666;
    color: #fff; }
  .personalInfo .signName {
    text-align: right; }

