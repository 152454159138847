@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body{
    font-family: 'Roboto', sans-serif;
}
.appBox{
    background-color: #E9EBEE;
    box-shadow: 0 2px 18px 0 rgba(182,178,178,.5);
    // padding-right:2rem;
    // padding-left: 2rem;
    overflow: hidden; 
}
.bgContext{
    background-color: #000;
}
.m-b-d{
    margin-bottom: 5rem;
}
.p-r-n{
    padding-right: 0 !important;
}
.p-l-n{
    padding-left: 0 !important;
}
.p-r-l-d{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.m-t-1{
    margin-top: 1rem;
}
.m-t-2{
    margin-top: 2rem;
}
.contextArea{
    background-color: #fff;
}
.footerBar{
    height: 2rem;
    background-color: #fc0;
}