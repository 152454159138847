.personalInfo{
    color: #333;
    .headText{
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0.2rem;
        background-color: #666;
        color: #fff;
    }
    .signName{
        text-align: right;
    }
}