.educationBox{
    color: #666;
    .headText{
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0.2rem;
        background-color: #666;
        color: #fff;
    }
    .eduBox{
        padding-top: 1rem;
        .headContext{
            font-size: 1.2rem;
            font-weight: 700;
            color: #111;
            margin-top: 0.5rem;
        }
        .mainContext{
            font-size: 1rem;
            // font-weight: 700;
            margin-left: 2rem;
            text-align: justify;
            color: #666;
            padding-top: 0.5rem;
        }
        .yearText{
            font-size: 0.8rem;
            font-weight: 800;
            margin-left: 1rem;
        }
    }
}